import React, { FC, useMemo, useState, useEffect, CSSProperties } from 'react';
import classNames from 'classnames';
import loadable from '@loadable/component';
import Container from 'react-bootstrap/Container';

import ELEMENTS, { COVID_BANNER_POSITION } from 'utils/constants';
import { isBrowser } from 'utils/browser';
import useScreenRecognition from 'hooks/useScreenRecognition';
import useOnDetectOrientation from 'hooks/useOnDetectOrientation';
import Header from 'components/Header';
import Footer from 'components/Footer';
import MobileAppPromoBanner from 'components/MobileAppPromoBanner';
import CovidBanner from 'components/CovidBanner';
import Seo from 'components/common/Seo';
import Disclaimer from 'components/Disclaimer';
import PageSchema from 'components/common/PageSchema';
import HeaderCustom from 'components/HeaderCustom';
import PromoModal from 'components/PromoModal';
import LegalPopup from 'components/LegalPopup';
import HCPPopup from 'components/HCPPopup/HCPPopup';

import { headerInlineStylesForCovidBanner, wrapperInlineStylesForCovidBanner } from './helpers';
import { ILayout } from './model';

import 'styles/main.scss';
import './Layout.scss';

const NewsletterPopups = loadable(() => import('components/NewsletterPopups'));

const Layout: FC<ILayout> = (props) => {
  const {
    children,
    className,
    seo,
    header,
    headerCustom,
    footer,
    siteSettings,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    promoModal,
    mobileAppPromoBanner,
    legalPopup,
    langSettings: { currentLang, langs },
    hcpPopup,
  } = props;
  const { isMobile } = useScreenRecognition();
  const [isShownCovidBanner, setIsShownCovidBanner] = useState<boolean | undefined>(
    () => isBrowser() && !!document.getElementById(ELEMENTS.COVID_BANNER_ID)
  );

  const { isPortraitOrientation: isOrientationChanged } = useOnDetectOrientation();
  const [headerInlineStyles, setHeaderInlineStyles] = useState<CSSProperties>({});
  const [wrapperInlineStyles, setWrapperInlineStyles] = useState<CSSProperties>({});

  const classes = classNames('layout', {
    [`${className}`]: className,
  });

  const handleSkipToContent = () => {
    const main = !isMobile && document.getElementById('siteMain');
    main && main.focus();
  };

  useEffect(() => {
    setWrapperInlineStyles(
      wrapperInlineStylesForCovidBanner({
        covidBanner,
        isShownBanner: isShownCovidBanner,
      })
    );

    setHeaderInlineStyles(
      headerInlineStylesForCovidBanner({
        covidBanner,
        isShownBanner: isShownCovidBanner,
      })
    );
  }, [isOrientationChanged, isShownCovidBanner, isMobile]);

  const covidBannerSection = useMemo(() => {
    const currentPage = langs?.length && langs[0].link;
    const shouldDisplayCovidOnCurrentPage = covidBanner?.listOfExcludedPages?.length
      ? !covidBanner.listOfExcludedPages.some(({ url }) => url === currentPage)
      : true;

    return covidBanner && shouldDisplayCovidOnCurrentPage ? (
      <CovidBanner
        {...covidBanner}
        isMobile={isMobile}
        callbackCloseBanner={setIsShownCovidBanner}
      />
    ) : null;
  }, [isShownCovidBanner]);

  const langSelector = useMemo(
    () =>
      langs?.length && siteSettings.languageSelector?.length
        ? {
            ...siteSettings.languageSelector[0].properties,
            languages: siteSettings.languageSelector[0].properties.languages.map(
              ({ properties, properties: { langCode, urlSuffix } }) => ({
                ...properties,
                active: langCode.toLowerCase() === currentLang,
                link:
                  langCode.toLowerCase() === currentLang
                    ? null
                    : langs.find(({ lang }) => lang === langCode)?.link || urlSuffix,
              })
            ),
            selected: siteSettings.languageSelector[0].properties.languages.find(
              ({ properties: { langCode } }) => langCode.toLowerCase() === currentLang
            )?.properties,
          }
        : undefined,
    [currentLang, langs]
  );

  return (
    <main className={classes}>
      {covidBanner?.position === COVID_BANNER_POSITION.top ? covidBannerSection : null}
      <Seo
        siteSettings={siteSettings}
        title={seo?.seoMetaTitle}
        description={seo?.seoMetaDescription}
        keywords={seo?.seoMetaKeywords}
        externalHreflangs={seo?.seoExternalHreflangs}
        seoContentType={seo?.seoContentType}
        ogImage={seo?.ogImage}
      />
      <a className="skip-to-main" href={`#${ELEMENTS.CONTENT_ID}`} onClick={handleSkipToContent}>
        {siteSettings.skipToContentLabel}
      </a>
      <div className="wrapper" style={wrapperInlineStyles}>
        {headerCustom ? (
          <HeaderCustom
            headerLogo={headerCustom.headerLogo}
            headerLogoAriaLabel={siteSettings.siteName}
            headerLogoTitle={headerCustom.headerLogoTitle}
            headerLogoLink={langSelector?.selected?.urlSuffix || '/'}
            navItems={headerCustom.navItems}
            inlineStylesForCovidBanner={headerInlineStyles}
            mediaLinks={footer.mediaLinks}
            hideSocialMediaBlock={headerCustom.hideSocialMediaBlock}
            headerMobileOpener={headerCustom.headerMobileOpener}
            buyNowBtn={headerCustom.buyNowBtn}
            sectionNavLandmark={headerCustom.sectionNavLandmark}
          />
        ) : (
          <Header
            navItems={header.navItems}
            headerLogo={header.headerLogo}
            headerLogoAriaLabel={siteSettings.siteName}
            headerLogoTitle={header.headerLogoTitle}
            headerLogoLink={langSelector?.selected?.urlSuffix || '/'}
            inlineStylesForCovidBanner={headerInlineStyles}
            mediaLinks={footer.mediaLinks}
            hideSocialMediaBlock={header.hideSocialMediaBlock}
            socialRedirectionBlock={header.socialRedirectionBlock}
            searchButtonAndLabelTexts={header.searchButtonAndLabelTexts}
            searchPath={searchPath?.nodes?.[0]?.link}
            headerMobileOpener={header.headerMobileOpener}
            langSelector={langSelector}
            marketName={siteSettings.lang.split('-')[1]}
            sectionNavLandmark={header.sectionNavLandmark}
            navigationButtonAriaLabel={header.navigationButtonAriaLabel}
          />
        )}
        <div id={ELEMENTS.CONTENT_ID}>
          <PageSchema
            type="WebPage"
            brandSettings={brandSettings}
            siteSettings={siteSettings}
            name={seo?.seoMetaTitle}
          />
          {children}
        </div>
        {mobileAppPromoBanner ? (
          <Container fluid>
            <MobileAppPromoBanner
              appLogo={mobileAppPromoBanner.appLogo}
              ingress={mobileAppPromoBanner.ingress}
              appstoreLinks={mobileAppPromoBanner.appstoreLinks}
            />
          </Container>
        ) : null}
        <Footer data={footer} lang={siteSettings.lang} isMobile={isMobile} />
      </div>
      {covidBanner?.position === COVID_BANNER_POSITION.bottom ? covidBannerSection : null}

      {newsletterPopupDefault || newsletterPopupDiscount || newsletterPopupFreeDelivery ? (
        <NewsletterPopups
          popups={[newsletterPopupDefault, newsletterPopupDiscount, newsletterPopupFreeDelivery]}
        />
      ) : null}
      {disclaimerPopup ? <Disclaimer {...disclaimerPopup} /> : null}
      {promoModal ? <PromoModal {...promoModal} /> : null}
      {legalPopup && legalPopup.legalPopupTitle ? <LegalPopup {...legalPopup} /> : null}
      {hcpPopup ? (
        <HCPPopup
          title={hcpPopup.title}
          description={hcpPopup.description}
          confirmButtonText={hcpPopup.confirmButtonText}
          confirmButtonTextAriaLabel={hcpPopup.confirmButtonTextAriaLabel}
          rejectButtonText={hcpPopup.rejectButtonText}
          rejectButtonTextAriaLabel={hcpPopup.rejectButtonTextAriaLabel}
          rejectRedirectUrl={hcpPopup.rejectRedirectUrl}
          closeButtonAriaLabel={hcpPopup.closeButtonAriaLabel}
        />
      ) : null}
    </main>
  );
};

export default Layout;
